import { store } from '@/store/index';
import { ref, Ref } from 'vue';

import { Sds } from '@/interfaces/model/general/sds';
import { User } from '@/interfaces/vuex/user';
import { NewSds } from '@/lib/constructor/model/general/sds';
import { readCompany } from '@/store/main/getters';
import { commitSetCompany } from '@/store/main/mutations';

import {
  DeleteCompanySdsesRequestBody,
  GetCompanySdsesCountRequestQuery,
  GetCompanySdsesRequestBody,
  UpdateCompanySdsesRequestBody,
  UpdateCompanySdsRequestBody,
} from '@/interfaces/api/general/company';
import {
  dispatchDeleteCompanySdses,
  dispatchGetCompanySds,
  dispatchGetCompanySdsPdf,
  dispatchGetCompanySdses,
  dispatchGetCompanySdsesCount,
  dispatchGetCompanyUploadUsers,
  dispatchUpdateCompanySds,
  dispatchUpdateCompanySdses,
} from '@/store/main/actionsMain';
import { DepartmentsState } from '@/interfaces/vuex/department';
import { TagResponse } from '@/interfaces/model/general/tag';

export interface SortState {
  [key: string]: 'asc' | 'desc' | 'none';
}

type UseSdsComposable = {
  sds: Ref<Sds>;
  sdses: Ref<Sds[]>;
  total: Ref<number>;
  pdfBlob: Ref<string>;
  companyUploadUsers: Ref<Array<User>>;
  updateCompanySds: (
    companyId: string,
    fileId: string,
    sds: Sds,
    department: DepartmentsState,
    tags: TagResponse[],
    userProfile: User
  ) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export const useSdsComposable = (): UseSdsComposable => {
  const sds = ref<Sds>({ ...NewSds });
  const sdses = ref<Array<Sds>>([]);
  const total = ref<number>(0);
  const pdfBlob = ref<string>('');
  const companyUploadUsers = ref<Array<User>>([]);

  const fetchCompanySdsesCount = async (data: {
    companyId: string;
    data: GetCompanySdsesCountRequestQuery;
  }): Promise<void> => {
    const res = await dispatchGetCompanySdsesCount(store, data);
    if (res) {
      commitSetCompany(store, {
        ...readCompany(store),
        uploaded_file_number: res.data.total,
      });
    }
  };

  const fetchCompanySdsesCountWithCondition = async (data: {
    companyId: string;
    data: GetCompanySdsesCountRequestQuery;
  }): Promise<void> => {
    const res = await dispatchGetCompanySdsesCount(store, data);
    if (res) {
      total.value = res.data.total;
    }
  };

  const fetchSdsPdf = async (data: {
    companyId: string;
    sdsId: string;
  }): Promise<void> => {
    const res = await dispatchGetCompanySdsPdf(store, {
      companyId: data.companyId,
      fileUuid: data.sdsId,
    });
    if (res) {
      pdfBlob.value = `data:application/pdf;base64,${res.data.data}`;
    }
  };

  const fetchCompanyUploadUsers = async (data: {
    companyId: string;
  }): Promise<void> => {
    companyUploadUsers.value.splice(0);

    const res = await dispatchGetCompanyUploadUsers(store, data);

    if (!res) {
      return;
    }

    companyUploadUsers.value.push(...res.data);
  };

  const fetchCompanySdses = async (data: {
    companyId: number;
    data: GetCompanySdsesRequestBody;
  }): Promise<void> => {
    sdses.value.splice(0);

    const res = await dispatchGetCompanySdses(store, data);
    if (res) {
      sdses.value.push(
        ...res.data.sdses.map((x) => {
          return { ...x };
        })
      );
    }
  };

  const fetchCompanySds = async (data: { sdsId: string }): Promise<void> => {
    const res = await dispatchGetCompanySds(store, {
      sdsId: data.sdsId,
      companyId: store.state.main.company.uuid,
    });
    if (res) {
      sds.value.tags = res.data.sds.tags;
      sds.value.id = res.data.sds.id;
      sds.value.uuid = res.data.sds.uuid;
      sds.value.name = res.data.sds.name;
      sds.value.replaced_name = res.data.sds.replaced_name;
      sds.value.note = res.data.sds.note;
      sds.value.admin_note = res.data.sds.admin_note;
      sds.value.company_id = res.data.sds.company_id;
      sds.value.departments = res.data.sds.departments;
      sds.value.status_id = res.data.sds.status_id;
      sds.value.updated_by = res.data.sds.updated_by;
      sds.value.timestamp = res.data.sds.timestamp;
      sds.value.published_at = res.data.sds.published_at;
      sds.value.revised_at = res.data.sds.revised_at;
      sds.value.created_at = res.data.sds.created_at;
      sds.value.updated_at = res.data.sds.updated_at;
      sds.value.supplier_confirmed_at = res.data.sds.supplier_confirmed_at;
      sds.value.deleted_at = res.data.sds.deleted_at;
      sds.value.assessed_at = res.data.sds.assessed_at;
      sds.value.sds_section14 = res.data.sds.sds_section14;
      sds.value.sds_section8_list = res.data.sds.sds_section8_list;
      sds.value.dynamic_list_result = res.data.sds.dynamic_list_result;
      sds.value.same_name_sds_info = res.data.sds.same_name_sds_info;
      sds.value.assistant_log_cross_checks =
        res.data.sds.assistant_log_cross_checks.map((x) => {
          const { id, sds_id, started_at, ended_at, deleted_at } = x;
          return {
            id,
            sds_id,
            started_at,
            ended_at,
            deleted_at,
          };
        });
      sds.value.sds_products = res.data.sds.sds_products.map((x) => {
        return {
          id: x.id,
          sds_id: x.sds_id,
          name: x.name,
          created_at: x.created_at,
          updated_at: x.updated_at,
          deleted_at: x.deleted_at,
          sds_section1_importers: x.sds_section1_importers.map((im) => {
            return {
              id: im.id,
              sds_id: im.sds_id,
              sds_product_id: im.sds_product_id,
              name: im.name,
              phone: im.phone,
              address: im.address,
              email: im.email,
              created_at: im.created_at,
              updated_at: im.updated_at,
              deleted_at: im.deleted_at,
            };
          }),
          sds_section1_manufacturers: x.sds_section1_manufacturers.map((im) => {
            return {
              id: im.id,
              sds_id: im.sds_id,
              sds_product_id: im.sds_product_id,
              name: im.name,
              phone: im.phone,
              address: im.address,
              email: im.email,
              created_at: im.created_at,
              updated_at: im.updated_at,
              deleted_at: im.deleted_at,
            };
          }),
          sds_section2_list: x.sds_section2_list.sort((a, b) =>
            a.item_id > b.item_id ? 1 : -1
          ),
          sds_section2_label: {
            id: x.sds_section2_label.id,
            sds_id: x.sds_section2_label.sds_id,
            sds_product_id: x.sds_section2_label.sds_product_id,
            bomb: x.sds_section2_label.bomb,
            fire: x.sds_section2_label.fire,
            fire_circle: x.sds_section2_label.fire_circle,
            gas: x.sds_section2_label.gas,
            corrosion: x.sds_section2_label.corrosion,
            toxic: x.sds_section2_label.toxic,
            exclamation: x.sds_section2_label.exclamation,
            health: x.sds_section2_label.health,
            environment: x.sds_section2_label.environment,
            signal_words: x.sds_section2_label.signal_words,
            created_at: x.sds_section2_label.created_at,
            updated_at: x.sds_section2_label.updated_at,
            deleted_at: x.sds_section2_label.deleted_at,
          },
          sds_section3: {
            id: x.sds_section3.id,
            sds_id: x.sds_section3.sds_id,
            sds_product_id: x.sds_section3.sds_product_id,
            created_at: x.sds_section3.created_at,
            updated_at: x.sds_section3.updated_at,
            deleted_at: x.sds_section3.deleted_at,
            composition_id: x.sds_section3.composition_id,
            material_list: x.sds_section3.material_list.map((s3) => {
              return {
                id: s3.id,
                sds_id: s3.sds_id,
                sds_section3_id: s3.sds_section3_id,
                material_name: s3.material_name,
                concentration: s3.concentration,
                encs_number: s3.encs_number,
                ishl_number: s3.ishl_number,
                is_stated: s3.is_stated,
                concentration_inequality_sign: s3.concentration_inequality_sign,
                concentration_min: s3.concentration_min,
                concentration_max: s3.concentration_max,
                concentration_unit: s3.concentration_unit,
                created_at: s3.created_at,
                updated_at: s3.updated_at,
                deleted_at: s3.deleted_at,
                cas_list: s3.cas_list.map((cas) => {
                  return {
                    id: cas.id,
                    sds_id: cas.sds_id,
                    sds_section3_material_id: cas.sds_section3_material_id,
                    cas_number: cas.cas_number,
                    created_at: cas.created_at,
                    updated_at: cas.updated_at,
                    deleted_at: cas.deleted_at,
                  };
                }),
              };
            }),
          },
          sds_section8_acgih_list: x.sds_section8_acgih_list.map((section8) => {
            return {
              id: section8.id,
              sds_id: section8.sds_id,
              sds_product_id: section8.sds_product_id,
              category_id: section8.category_id,
              concentration: section8.concentration,
              concentration_unit: section8.concentration_unit,
              concentration_note: section8.concentration_note,
              amount: section8.amount,
              amount_unit: section8.amount_unit,
              amount_note: section8.amount_note,
              other: section8.other,
              created_at: section8.created_at,
              updated_at: section8.updated_at,
              deleted_at: section8.deleted_at,
              material_list: section8.material_list.map((material) => {
                return {
                  id: material.id,
                  sds_id: material.sds_id,
                  sds_section8_acgih_id: material.sds_section8_acgih_id,
                  material_name: material.material_name,
                  created_at: material.created_at,
                  updated_at: material.updated_at,
                  deleted_at: material.deleted_at,
                  cas_list: material.cas_list.map((cas) => {
                    return {
                      id: cas.id,
                      sds_id: cas.sds_id,
                      sds_section8_acgih_material_id:
                        cas.sds_section8_acgih_material_id,
                      cas_number: cas.cas_number,
                      created_at: cas.created_at,
                      updated_at: cas.updated_at,
                      deleted_at: cas.deleted_at,
                    };
                  }),
                };
              }),
            };
          }),
          sds_section8_jsoh_list: x.sds_section8_jsoh_list.map((section8) => {
            return {
              id: section8.id,
              sds_id: section8.sds_id,
              sds_product_id: section8.sds_product_id,
              category_concentration_id: section8.category_concentration_id,
              category_dust_id: section8.category_dust_id,
              category_dust_respirable_id: section8.category_dust_respirable_id,
              concentration: section8.concentration,
              concentration_unit: section8.concentration_unit,
              concentration_note: section8.concentration_note,
              amount: section8.amount,
              amount_unit: section8.amount_unit,
              amount_note: section8.amount_note,
              other: section8.other,
              created_at: section8.created_at,
              updated_at: section8.updated_at,
              deleted_at: section8.deleted_at,
              material_list: section8.material_list.map((material) => {
                return {
                  id: material.id,
                  sds_id: material.sds_id,
                  sds_section8_jsoh_id: material.sds_section8_jsoh_id,
                  material_name: material.material_name,
                  created_at: material.created_at,
                  updated_at: material.updated_at,
                  deleted_at: material.deleted_at,
                  cas_list: material.cas_list.map((cas) => {
                    return {
                      id: cas.id,
                      sds_id: cas.sds_id,
                      sds_section8_jsoh_material_id:
                        cas.sds_section8_jsoh_material_id,
                      cas_number: cas.cas_number,
                      created_at: cas.created_at,
                      updated_at: cas.updated_at,
                      deleted_at: cas.deleted_at,
                    };
                  }),
                };
              }),
            };
          }),
          sds_section8_dnel_dmel_list: x.sds_section8_dnel_dmel_list.map(
            (section8) => {
              return {
                id: section8.id,
                sds_id: section8.sds_id,
                sds_product_id: section8.sds_product_id,
                category_user_id: section8.category_user_id,
                category_term_id: section8.category_term_id,
                category_dust_route_id: section8.category_dust_route_id,
                category_measurement_id: section8.category_measurement_id,
                concentration: section8.concentration,
                concentration_unit: section8.concentration_unit,
                concentration_note: section8.concentration_note,
                amount: section8.amount,
                amount_unit: section8.amount_unit,
                amount_note: section8.amount_note,
                other: section8.other,
                created_at: section8.created_at,
                updated_at: section8.updated_at,
                deleted_at: section8.deleted_at,
                material_list: section8.material_list.map((material) => {
                  return {
                    id: material.id,
                    sds_id: material.sds_id,
                    sds_section8_dnel_dmel_id:
                      material.sds_section8_dnel_dmel_id,
                    material_name: material.material_name,
                    created_at: material.created_at,
                    updated_at: material.updated_at,
                    deleted_at: material.deleted_at,
                    cas_list: material.cas_list.map((cas) => {
                      return {
                        id: cas.id,
                        sds_id: cas.sds_id,
                        sds_section8_dnel_dmel_material_id:
                          cas.sds_section8_dnel_dmel_material_id,
                        cas_number: cas.cas_number,
                        created_at: cas.created_at,
                        updated_at: cas.updated_at,
                        deleted_at: cas.deleted_at,
                      };
                    }),
                  };
                }),
              };
            }
          ),
          sds_section9_list: x.sds_section9_list.map((s9) => {
            return {
              id: s9.id,
              sds_id: s9.sds_id,
              sds_product_id: s9.sds_product_id,
              condition: s9.condition,
              flash_point: s9.flash_point,
              physical_condition: s9.physical_condition,
              form: s9.form,
              measurement_method: s9.measurement_method,
              created_at: s9.created_at,
              updated_at: s9.updated_at,
              deleted_at: s9.deleted_at,
            };
          }),
          sds_section9_flash_point_list: x.sds_section9_flash_point_list.map(
            (f) => {
              return {
                id: f.id,
                sds_id: f.sds_id,
                sds_product_id: f.sds_product_id,
                flash_point_min: f.flash_point_min,
                flash_point_max: f.flash_point_max,
                flash_point_inequality_sign: f.flash_point_inequality_sign,
                unit_id: f.unit_id,
                flash_point: f.flash_point,
                measurement_method: f.measurement_method,
                note: f.note,
                created_at: f.created_at,
                updated_at: f.updated_at,
                deleted_at: f.deleted_at,
              };
            }
          ),
          sds_section9_boiling_point_list:
            x.sds_section9_boiling_point_list.map((f) => {
              return {
                id: f.id,
                sds_id: f.sds_id,
                sds_product_id: f.sds_product_id,
                boiling_point_min: f.boiling_point_min,
                boiling_point_max: f.boiling_point_max,
                boiling_point_inequality_sign: f.boiling_point_inequality_sign,
                unit_id: f.unit_id,
                boiling_point: f.boiling_point,
                measurement_method: f.measurement_method,
                note: f.note,
                created_at: f.created_at,
                updated_at: f.updated_at,
                deleted_at: f.deleted_at,
              };
            }),
          sds_section15_list: x.sds_section15_list.map((section15) => {
            return {
              id: section15.id,
              sds_id: section15.sds_id,
              sds_product_id: section15.sds_product_id,
              law_id: section15.law_id,
              regulation1_id: section15.regulation1_id,
              regulation2_id: section15.regulation2_id,
              regulation3_id: section15.regulation3_id,
              regulation4_id: section15.regulation4_id,
              regulation5_id: section15.regulation5_id,
              applicable: section15.applicable,
              text: section15.text,
              created_at: section15.created_at,
              updated_at: section15.updated_at,
              deleted_at: section15.deleted_at,
              material_list: section15.material_list.map((material) => {
                return {
                  id: material.id,
                  sds_id: material.sds_id,
                  sds_section15_id: material.sds_section15_id,
                  material_name: material.material_name,
                  created_at: material.created_at,
                  updated_at: material.updated_at,
                  deleted_at: material.deleted_at,
                  cas_list: material.cas_list.map((cas) => {
                    return {
                      id: cas.id,
                      sds_id: cas.sds_id,
                      sds_section15_material_id: cas.sds_section15_material_id,
                      cas_number: cas.cas_number,
                      created_at: cas.created_at,
                      updated_at: cas.updated_at,
                      deleted_at: cas.deleted_at,
                    };
                  }),
                };
              }),
            };
          }),
        };
      });
    }
  };

  const updateCompanySds = async (
    companyId: string,
    fileId: string,
    sds: Sds,
    department: DepartmentsState,
    tags: TagResponse[],
    userProfile: User
  ): Promise<void> => {
    await dispatchUpdateCompanySds(store, {
      companyId: companyId,
      fileId: fileId,
      form: convertSdsToSdsRequestBody(sds, department, tags, userProfile),
    });
  };

  const updateCompanySdses = async (data: {
    companyId: string;
    data: UpdateCompanySdsesRequestBody;
  }): Promise<void> => {
    await dispatchUpdateCompanySdses(store, data);
  };

  const deleteCompanySdses = async (data: {
    companyId: string;
    data: DeleteCompanySdsesRequestBody;
  }): Promise<void> => {
    await dispatchDeleteCompanySdses(store, data);
  };

  return {
    sds,
    sdses,
    total,
    pdfBlob,
    companyUploadUsers,
    fetchCompanyUploadUsers,
    fetchCompanySds,
    fetchCompanySdses,
    fetchSdsPdf,
    fetchCompanySdsesCount,
    fetchCompanySdsesCountWithCondition,
    updateCompanySds,
    deleteCompanySdses,
    updateCompanySdses,
  };
};

const convertSdsToSdsRequestBody = (
  sds: Sds,
  departments: DepartmentsState,
  tags: TagResponse[],
  userProfile: User
): UpdateCompanySdsRequestBody => ({
  name: sds.name,
  department_id_list: departments.map((d) => d.uuid),
  tag_id_list: tags.map((t) => t.id.toString()),
  note: sds.note,
  status_id: sds.status_id,
  published_at: sds.published_at,
  revised_at: sds.revised_at,
  assessed_at: sds.assessed_at,
  updated_by: userProfile.uuid,
  supplier_confirmed_at: sds.supplier_confirmed_at,
  deleted_at: sds.deleted_at,
  sds_section14: {
    id: sds.sds_section14.id,
    sds_id: sds.sds_section14.sds_id,
    un_number: sds.sds_section14.un_number,
    deleted_at: sds.sds_section14.deleted_at,
  },
  sds_section8_list: sds.sds_section8_list.map((section8) => ({
    id: section8.id,
    sds_id: section8.sds_id,
    skin_protection: section8.skin_protection,
    deleted_at: section8.deleted_at,
  })),
  products: sds.sds_products.map((x) => ({
    id: x.id,
    sds_id: x.sds_id,
    name: x.name,
    deleted_at: x.deleted_at,
    sds_section1_importers: x.sds_section1_importers.map((im) => ({
      id: im.id,
      sds_id: im.sds_id,
      sds_product_id: im.sds_product_id,
      name: im.name,
      phone: im.phone,
      address: im.address,
      email: im.email,
      deleted_at: im.deleted_at,
    })),
    sds_section1_manufacturers: x.sds_section1_manufacturers.map((im) => ({
      id: im.id,
      sds_id: im.sds_id,
      sds_product_id: im.sds_product_id,
      name: im.name,
      phone: im.phone,
      address: im.address,
      email: im.email,
      deleted_at: im.deleted_at,
    })),
    sds_section2_list: x.sds_section2_list.map((x) => ({
      id: x.id,
      sds_id: x.sds_id,
      sds_product_id: x.sds_product_id,
      item_id: x.item_id,
      category_id: x.category_id,
      deleted_at: x.deleted_at,
      sds_section2_hazard_list: x.sds_section2_hazard_list.map((y) => ({
        id: y.id,
        sds_id: y.sds_id,
        sds_section2_id: y.sds_section2_id,
        hazard_id: y.hazard_id,
        deleted_at: y.deleted_at,
      })),
    })),
    sds_section2_label: {
      id: x.sds_section2_label.id,
      sds_id: x.sds_section2_label.sds_id,
      sds_product_id: x.sds_section2_label.sds_product_id,
      bomb: x.sds_section2_label.bomb,
      fire: x.sds_section2_label.fire,
      fire_circle: x.sds_section2_label.fire_circle,
      gas: x.sds_section2_label.gas,
      corrosion: x.sds_section2_label.corrosion,
      toxic: x.sds_section2_label.toxic,
      exclamation: x.sds_section2_label.exclamation,
      health: x.sds_section2_label.health,
      environment: x.sds_section2_label.environment,
      signal_words: x.sds_section2_label.signal_words,
      deleted_at: x.sds_section2_label.deleted_at,
    },
    sds_section3: {
      id: x.sds_section3.id,
      sds_id: x.sds_section3.sds_id,
      sds_product_id: x.sds_section3.sds_product_id,
      composition_id: x.sds_section3.composition_id,
      deleted_at: x.sds_section3.deleted_at,
      material_list: x.sds_section3.material_list.map((s3) => ({
        id: s3.id,
        sds_id: s3.sds_id,
        sds_section3_id: s3.sds_section3_id,
        material_name: s3.material_name,
        concentration: s3.concentration,
        concentration_inequality_sign: s3.concentration_inequality_sign,
        concentration_unit: s3.concentration_unit,
        concentration_min: s3.concentration_min,
        concentration_max: s3.concentration_max,
        encs_number: s3.encs_number,
        ishl_number: s3.ishl_number,
        is_stated: s3.is_stated,
        deleted_at: s3.deleted_at,
        cas_list: s3.cas_list.map((cas) => ({
          id: s3.id,
          sds_id: s3.sds_id,
          sds_section3_material_id: cas.sds_section3_material_id,
          cas_number: cas.cas_number,
          deleted_at: cas.deleted_at,
        })),
      })),
    },
    sds_section8_acgih_list: x.sds_section8_acgih_list.map((section8) => ({
      id: section8.id,
      sds_id: section8.sds_id,
      sds_product_id: section8.sds_product_id,
      category_id: section8.category_id,
      concentration: section8.concentration,
      concentration_unit: section8.concentration_unit,
      concentration_note: section8.concentration_note,
      amount: section8.amount,
      amount_unit: section8.amount_unit,
      amount_note: section8.amount_note,
      other: section8.other,
      deleted_at: section8.deleted_at,
      material_list: section8.material_list.map((material) => ({
        id: material.id,
        sds_id: material.sds_id,
        sds_section8_acgih_id: material.sds_section8_acgih_id,
        material_name: material.material_name,
        deleted_at: material.deleted_at,
        cas_list: material.cas_list.map((cas) => ({
          id: cas.id,
          sds_id: cas.sds_id,
          sds_section8_acgih_material_id: cas.sds_section8_acgih_material_id,
          cas_number: cas.cas_number,
          deleted_at: cas.deleted_at,
        })),
      })),
    })),
    sds_section8_jsoh_list: x.sds_section8_jsoh_list.map((section8) => ({
      id: section8.id,
      sds_id: section8.sds_id,
      sds_product_id: section8.sds_product_id,
      category_concentration_id: section8.category_concentration_id,
      category_dust_id: section8.category_dust_id,
      category_dust_respirable_id: section8.category_dust_respirable_id,
      concentration: section8.concentration,
      concentration_unit: section8.concentration_unit,
      concentration_note: section8.concentration_note,
      amount: section8.amount,
      amount_unit: section8.amount_unit,
      amount_note: section8.amount_note,
      other: section8.other,
      deleted_at: section8.deleted_at,
      material_list: section8.material_list.map((material) => ({
        id: material.id,
        sds_id: material.sds_id,
        sds_section8_jsoh_id: material.sds_section8_jsoh_id,
        material_name: material.material_name,
        deleted_at: material.deleted_at,
        cas_list: material.cas_list.map((cas) => ({
          id: cas.id,
          sds_id: cas.sds_id,
          sds_section8_jsoh_material_id: cas.sds_section8_jsoh_material_id,
          cas_number: cas.cas_number,
          deleted_at: cas.deleted_at,
        })),
      })),
    })),
    sds_section8_dnel_dmel_list: x.sds_section8_dnel_dmel_list.map(
      (section8) => ({
        id: section8.id,
        sds_id: section8.sds_id,
        sds_product_id: section8.sds_product_id,
        category_user_id: section8.category_user_id,
        category_term_id: section8.category_term_id,
        category_dust_route_id: section8.category_dust_route_id,
        category_measurement_id: section8.category_measurement_id,
        concentration: section8.concentration,
        concentration_unit: section8.concentration_unit,
        concentration_note: section8.concentration_note,
        amount: section8.amount,
        amount_unit: section8.amount_unit,
        amount_note: section8.amount_note,
        other: section8.other,
        deleted_at: section8.deleted_at,
        material_list: section8.material_list.map((material) => ({
          id: material.id,
          sds_id: material.sds_id,
          sds_section8_dnel_dmel_id: material.sds_section8_dnel_dmel_id,
          material_name: material.material_name,
          deleted_at: material.deleted_at,
          cas_list: material.cas_list.map((cas) => ({
            id: cas.id,
            sds_id: cas.sds_id,
            sds_section8_dnel_dmel_material_id:
              cas.sds_section8_dnel_dmel_material_id,
            cas_number: cas.cas_number,
            deleted_at: cas.deleted_at,
          })),
        })),
      })
    ),
    sds_section9_list: x.sds_section9_list.map((s9) => ({
      id: s9.id,
      sds_id: x.sds_id,
      sds_product_id: s9.sds_product_id,
      condition: s9.condition,
      flash_point: s9.flash_point,
      physical_condition: s9.physical_condition,
      form: s9.form,
      measurement_method: s9.measurement_method,
      deleted_at: s9.deleted_at,
    })),
    sds_section9_flash_point_list: x.sds_section9_flash_point_list.map((f) => ({
      id: f.id,
      sds_id: f.sds_id,
      sds_product_id: f.sds_product_id,
      flash_point_min: f.flash_point_min,
      flash_point_max: f.flash_point_max,
      flash_point_inequality_sign: f.flash_point_inequality_sign,
      unit_id: f.unit_id,
      flash_point: f.flash_point,
      measurement_method: f.measurement_method,
      note: f.note,
      created_at: f.created_at,
      updated_at: f.updated_at,
      deleted_at: f.deleted_at,
    })),
    sds_section9_boiling_point_list: x.sds_section9_boiling_point_list.map(
      (f) => ({
        id: f.id,
        sds_id: f.sds_id,
        sds_product_id: f.sds_product_id,
        boiling_point_min: f.boiling_point_min,
        boiling_point_max: f.boiling_point_max,
        boiling_point_inequality_sign: f.boiling_point_inequality_sign,
        unit_id: f.unit_id,
        boiling_point: f.boiling_point,
        measurement_method: f.measurement_method,
        note: f.note,
        created_at: f.created_at,
        updated_at: f.updated_at,
        deleted_at: f.deleted_at,
      })
    ),
    sds_section15_list: x.sds_section15_list.map((section15) => ({
      id: section15.id,
      sds_id: section15.sds_id,
      sds_product_id: section15.sds_product_id,
      law_id: section15.law_id,
      regulation1_id: section15.regulation1_id,
      regulation2_id: section15.regulation2_id,
      regulation3_id: section15.regulation3_id,
      regulation4_id: section15.regulation4_id,
      regulation5_id: section15.regulation5_id,
      applicable: section15.applicable,
      text: section15.text,
      deleted_at: section15.deleted_at,
      material_list: section15.material_list.map((material) => ({
        id: material.id,
        sds_id: material.sds_id,
        sds_section15_id: material.sds_section15_id,
        material_name: material.material_name,
        deleted_at: material.deleted_at,
        cas_list: material.cas_list.map((cas) => ({
          id: cas.id,
          sds_id: cas.sds_id,
          sds_section15_material_id: cas.sds_section15_material_id,
          cas_number: cas.cas_number,
          deleted_at: cas.deleted_at,
        })),
      })),
    })),
  })),
});
