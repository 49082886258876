
import { computed, defineComponent, onMounted, ref, watch, Ref } from 'vue';
import { NavigationGuardNext, Route } from 'vue-router';

import { DynamicList } from '@/interfaces/api/general/dynamicList';
import { usePermissionComposable } from '@/composables/usePermission';
import { FormItem } from '@/interfaces/lib/model';
import { DynamicListResultFilterCriteria } from '@/lib/filterCriteria';
import { Sds, SdsDynamicListResult } from '@/interfaces/model/general/sds';
import { Department } from '@/interfaces/model/general/department';
import { MAX_LIMIT } from '@/lib/constant';
import { NewDynamicList } from '@/lib/constructor/model/general/dynamicList';
import { StatusId } from '@/lib/enum';
import { pdfDisplayOptions, statusList } from '@/lib/formItem';
import { formatToDate, removeLastPdfExtension } from '@/lib/formatter';
import router from '@/router';
import { store } from '@/store/index';
import { readDepartments } from '@/store/main/getters';
import {
  dispatchCancelCreateSimpleExportRequest,
  dispatchCreateReanalysisSds,
  dispatchDeleteDynamicListResult,
  dispatchGetCompanySdsesCount,
  dispatchGetDynamicList,
  dispatchGetDynamicListResults,
  dispatchGetDynamicLists,
  dispatchGetExportCreateSimple,
  dispatchGetExportCreateSimpleV3,
  dispatchGetExportDynamicListResults,
  dispatchPostDynamicListResult,
  dispatchPutDynamicListResult,
} from '@/store/main/actionsMain';
import DynamicListResultTable from '@/views/pages/dynamicListDetail/DynamicListResultTable.vue';
import SdsSearchForm from '@/views/dashboard/SdsSearchForm.vue';
import SdsTableDrawer from '@/views/dashboard/sdsDrawer/SdsTableDrawer.vue';
import { useSdsComposable, SortState } from '@/composables/useSds';

import { casNumberPattern } from '@/lib/regex';
import DownloadProgress from '@/views/dashboard/DownloadProgress.vue';
import { TagResponse } from '@/interfaces/model/general/tag';
import { useTagComposable } from '@/composables/useTag';
import { useDepartmentComposable } from '@/composables/useDepartment';

export default defineComponent({
  components: {
    SdsTableDrawer,
    SdsSearchForm,
    DynamicListResultTable,
    DownloadProgress,
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (this.isEditing) {
      const isAllowed: boolean = window.confirm(
        '編集中の作業は保存されませんが、よろしいですか？'
      );
      if (isAllowed) {
        next();
      }
    } else {
      next();
    }
  },
  setup() {
    const { hasPermission, PERMISSIONS: P } = usePermissionComposable();

    const nextUpdateDate = () => {
      const japanNow = new Date(
        new Date().toLocaleString('en-US', { timeZone: 'Asia/Tokyo' })
      );

      japanNow.setDate(japanNow.getDate() + 1);
      japanNow.setHours(0, 30, 0, 0);

      const nextUpdateTime = new Date(
        japanNow.toLocaleString('en-US', {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
      );

      const year = nextUpdateTime.getFullYear();
      const month = nextUpdateTime.getMonth() + 1;
      const date = nextUpdateTime.getDate();
      const hours = nextUpdateTime.getHours();
      const minutes = nextUpdateTime.getMinutes();

      return `${year}年${month}月${date}日${hours}時${minutes}分`;
    };
    const showSdsDetailView = ref<boolean>(false);
    const pdfName = ref<string>('');
    const keywordList = ref<Array<string>>([]);
    const productFormItems = ref<Array<{ text: string; value: number }>>([]);
    const materialFormItems = ref<Array<FormItem>>([]);
    const casFormItems = ref<Array<FormItem>>([]);
    const page = ref<number>(1);
    const limit = ref<number>(50);
    const isLoadingSdses = ref<boolean>(false);
    const isLoadingSds = ref<boolean>(false);
    const isEditing = ref<boolean>(false);
    const statusIdList = ref<Array<number>>([...StatusId.values()]);
    const dynamicList = ref<DynamicList>({ ...NewDynamicList });
    const uploadedTotal = ref<number>(0);
    const lastCount = ref<number>(0);
    const companyFiles = ref<Sds[]>([]);
    const canExportCreateSimpleCount = ref<number>(0);
    const canExportCreateSimpleCountV3 = ref<number>(0);
    const dialogShow = ref<boolean>(false);
    const reanalysisSdsModalShow = ref<boolean>(false);
    const reanalysisSdsComment = ref<{
      comment: string;
      sds_id: string;
    }>({
      comment: '',
      sds_id: '',
    });
    const dynamicLists = ref<Array<DynamicList>>([]);
    const activeSds = ref<Sds>();
    const departments = computed(() => readDepartments(store));
    const {
      sds,
      sdses,
      total,
      pdfBlob,
      companyUploadUsers,
      fetchCompanyUploadUsers,
      fetchCompanySds,
      fetchCompanySdses,
      fetchSdsPdf,
      fetchCompanySdsesCount,
      fetchCompanySdsesCountWithCondition,
      updateCompanySds,
    } = useSdsComposable();

    const selectableStatusList: Array<StatusId> = StatusId.getDropdownOptions();
    const statusTextList: Array<string> = Array.from(
      new Set(selectableStatusList.map((status) => status.groupId))
    );

    const searchUserText = ref<string>('');
    const searchTagText = ref<string>('');
    const searchDepartmentText = ref<string>('');

    const isDialogVisibleRevisedAtStartDate = ref(false);
    const isDialogVisibleRevisedAtEndDate = ref(false);
    const isDialogVisibleCreatedAtStartDate = ref(false);
    const isDialogVisibleCreatedAtEndDate = ref(false);
    const isDialogVisibleUpdatedAtStartDate = ref(false);
    const isDialogVisibleUpdatedAtEndDate = ref(false);

    const saveDate = (state) => {
      if (state == 1) {
        isDialogVisibleRevisedAtStartDate.value = false;
      }
      if (state == 2) {
        isDialogVisibleRevisedAtEndDate.value = false;
      }
      if (state == 3) {
        isDialogVisibleCreatedAtStartDate.value = false;
      }
      if (state == 4) {
        isDialogVisibleCreatedAtEndDate.value = false;
      }
      if (state == 5) {
        isDialogVisibleUpdatedAtStartDate.value = false;
      }
      if (state == 6) {
        isDialogVisibleUpdatedAtEndDate.value = false;
      }
    };

    const hasFilterCriteria = computed(() => {
      return (
        filterCriteria.value.uploadUsers.length ||
        filterCriteria.value.tags.length ||
        filterCriteria.value.departments.length ||
        filterCriteria.value.pdfName ||
        filterCriteria.value.productName ||
        filterCriteria.value.cause ||
        filterCriteria.value.note ||
        filterCriteria.value.revisedAtStartDate ||
        filterCriteria.value.revisedAtEndDate ||
        filterCriteria.value.createdAtStartDate ||
        filterCriteria.value.createdAtEndDate ||
        filterCriteria.value.updatedAtStartDate ||
        filterCriteria.value.updatedAtEndDate
      );
    });

    const resetFilters = async (): Promise<void> => {
      filterCriteria.value.uploadUsers = [];
      filterCriteria.value.tags = [];
      filterCriteria.value.departments = [];
      filterCriteria.value.pdfName = '';
      filterCriteria.value.productName = '';
      filterCriteria.value.cause = '';
      filterCriteria.value.note = '';
      filterCriteria.value.revisedAtStartDate = '';
      filterCriteria.value.revisedAtEndDate = '';
      filterCriteria.value.createdAtStartDate = '';
      filterCriteria.value.createdAtEndDate = '';
      filterCriteria.value.updatedAtStartDate = '';
      filterCriteria.value.updatedAtEndDate = '';
      tempfilterCriteria.value = { ...filterCriteria.value };
      applyFilters();
    };

    const cancelFilters = async (): Promise<void> => {
      isFilterPanelVisible.value = false;
    };

    const { getCompanyDepartments, companyDepartments } =
      useDepartmentComposable();

    const { tags, getCompanyTag } = useTagComposable();

    const isFilterPanelVisible = ref(false);

    const toggleFilterPane = (): void => {
      isFilterPanelVisible.value = !isFilterPanelVisible.value;
    };

    const uploadingToCompletedStatusIdList = ref<Array<number>>([
      ...StatusId.uploadingToCompletedValues(),
    ]);

    const cancelExportCreateSimple = (): void => {
      dispatchCancelCreateSimpleExportRequest(store);
      dialogShow.value = false;
    };

    const openReanalysisSdsModal = (sdsId: string): void => {
      reanalysisSdsModalShow.value = true;
      reanalysisSdsComment.value = {
        comment: '',
        sds_id: sdsId,
      };
    };

    const closeReanalysisSdsModal = (): void => {
      reanalysisSdsModalShow.value = false;
    };

    const reanalysisSds = async (): Promise<void> => {
      const payload = {
        sdsId: reanalysisSdsComment.value.sds_id,
        comment: reanalysisSdsComment.value.comment,
      };
      await dispatchCreateReanalysisSds(store, payload);
      closeReanalysisSdsModal();

      await doFetchCompanySdses();
      await doFetchCompanySdsesCount();
      await doFetchCompanySdsesCountWithCondition();

      await doFetchCompanySds(reanalysisSdsComment.value.sds_id);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    watch(showSdsDetailView, (next, prev) => {
      if (next === false) {
        activeSds.value = undefined;
      }
    });

    const currentIndex = ref(0);
    const hasPrevSds = computed((): boolean => {
      return currentIndex.value > 0;
    });

    const hasNextSds = computed((): boolean => {
      return currentIndex.value < companyFiles.value.length - 1;
    });

    const showPrevSds = async (): Promise<void> => {
      const index = currentIndex.value - 1;

      showSdsDetail(companyFiles.value[index], index);
    };

    const showNextSds = async (): Promise<void> => {
      const index = currentIndex.value + 1;

      showSdsDetail(companyFiles.value[index], index);
    };

    const resetPagenation = (): void => {
      page.value = 1;
    };

    const resetSearchForm = (): void => {
      keywordList.value = [];
    };

    const showSdsDetail = async (item: Sds, index: number): Promise<void> => {
      await doFetchCompanySds(item.uuid);
      await doFetchPdf(item.uuid);

      showSdsDetailView.value = true;
      isLoadingSds.value = true;
      canExportCreateSimpleCount.value = 0;
      canExportCreateSimpleCountV3.value = 0;

      activeSds.value = item;
      currentIndex.value = index;

      productFormItems.value.splice(0);
      productFormItems.value.push(
        ...sds.value.sds_products.map((x, idx) => {
          return {
            text: x.name,
            value: idx,
          };
        })
      );
      materialFormItems.value.splice(0);
      casFormItems.value.splice(0);
      for (const product of sds.value.sds_products) {
        // material_list内のcas_listをcasNumberPatternでマッチングし、1つ以上マッチしたらcanExportCreateSimpleCountV3をインクリメント
        const casList = product.sds_section3.material_list
          .map((x) => x.cas_list)
          .flat()
          .filter((x) => x.cas_number.match(casNumberPattern));
        if (casList.length > 0) {
          canExportCreateSimpleCountV3.value++;
        }

        for (const material of product.sds_section3.material_list) {
          materialFormItems.value.push({
            text: material.material_name,
            value: material.material_name,
          });
          for (const cas of material.cas_list) {
            casFormItems.value.push({
              text: cas.cas_number,
              value: cas.cas_number,
            });
            if (cas.cas_number.match(casNumberPattern)) {
              canExportCreateSimpleCount.value++;
            }
          }
        }
      }

      isLoadingSds.value = false;
    };

    const doFetchPdf = async (sdsId: string): Promise<void> => {
      fetchSdsPdf({
        companyId: store.state.main.company.uuid,
        sdsId: sdsId,
      });
    };

    const doFetchCompanySds = async (sdsId: string): Promise<void> => {
      await fetchCompanySds({
        sdsId: sdsId,
        companyId: store.state.main.company.uuid,
      });
    };

    const doFetchCompanyUploadUsers = async (): Promise<void> => {
      //fetch users from the same company with the current user
      await fetchCompanyUploadUsers({
        companyId: store.state.main.company.uuid,
      });
    };

    const doFetchCompanySdses = async (): Promise<void> => {
      isLoadingSdses.value = true;

      await fetchCompanySdses({
        companyId: store.state.main.company.uuid,
        data: {
          limit: limit.value,
          page: page.value - 1,
          keyword_list: keywordList.value,
          status_id_list: statusIdList.value,
          has_product: true,
          has_section1: false,
          has_section2: false,
          has_section3: true,
          has_section8: false,
          has_section8_acgih: false,
          has_section8_jsoh: false,
          has_section8_dnel_dmel: false,
          has_section9: false,
          has_section14: false,
          has_section15: false,
          has_assistant_log_cannot_analysis: false,
        },
      });

      isLoadingSdses.value = false;
    };

    const doFetchCompanySdsesCountWithCondition = async (): Promise<void> => {
      isLoadingSdses.value = true;

      await fetchCompanySdsesCountWithCondition({
        companyId: store.state.main.company.uuid,
        data: {
          keyword_list: keywordList.value,
          status_id_list: statusIdList.value,
        },
      });

      isLoadingSdses.value = false;
    };

    const doFetchCompanySdsesCount = async (): Promise<void> => {
      isLoadingSdses.value = true;

      await fetchCompanySdsesCount({
        companyId: store.state.main.company.uuid,
        data: {
          keyword_list: keywordList.value,
        },
      });

      isLoadingSdses.value = false;
    };

    const doUpdateCompanySds = async (
      sdsId: string,
      data: Sds,
      tags: TagResponse[],
      departments: Department[]
    ): Promise<void> => {
      await updateCompanySds(
        store.state.main.company.uuid,
        sdsId,
        data,
        departments,
        tags,
        store.state.main.userProfile
      );
      await doFetchCompanySds(data.uuid);
      await doFetchCompanySdses();
      await doFetchCompanySdsesCount();
      await doFetchCompanySdsesCountWithCondition();
      await fetchDynamicListResults();
    };

    const downloadExportData = (): void => {
      const params = new URLSearchParams(location.href.split('?')[1]);
      const isExport = params.get('export');

      if (isExport) {
        const link = document.createElement('a');
        const bucket = params.get('bucket');
        const companyId = params.get('companyId');
        const filename = params.get('filename');
        const query = {
          'X-Goog-Algorithm': params.get('X-Goog-Algorithm') ?? '',
          'X-Goog-Credential': params.get('X-Goog-Credential') ?? '',
          'X-Goog-Date': params.get('X-Goog-Date') ?? '',
          'X-Goog-Expires': params.get('X-Goog-Expires') ?? '',
          'X-Goog-SignedHeaders': params.get('X-Goog-SignedHeaders') ?? '',
          'X-Goog-Signature': params.get('X-Goog-Signature') ?? '',
        };
        const urlSearchParam = new URLSearchParams(query).toString();
        const url = `https://storage.googleapis.com/${bucket}/export/${companyId}/${filename}?${urlSearchParam}`;
        link.href = url;
        link.download = 'SDS一覧.xlsx';
        link.click();
      }
    };

    const cancelEdit = async (sdsId: string): Promise<void> => {
      isLoadingSds.value = true;

      await doFetchCompanySds(sdsId);

      productFormItems.value.splice(0);
      productFormItems.value.push(
        ...sds.value.sds_products.map((x, idx) => {
          return {
            text: x.name,
            value: idx,
          };
        })
      );
      materialFormItems.value.splice(0);
      casFormItems.value.splice(0);
      for (const product of sds.value.sds_products) {
        for (const material of product.sds_section3.material_list) {
          materialFormItems.value.push({
            text: material.material_name,
            value: material.material_name,
          });
          for (const cas of material.cas_list) {
            casFormItems.value.push({
              text: cas.cas_number,
              value: cas.cas_number,
            });
          }
        }
      }

      isLoadingSds.value = false;
    };

    const confirmSave = (event): void => {
      if (isEditing.value) {
        event.preventDefault();
        event.returnValue = '編集中の作業は保存されませんが、よろしいですか？';
      }
    };

    const fetchCompanySdsesCountWithAll = async (): Promise<void> => {
      const res = await dispatchGetCompanySdsesCount(store, {
        companyId: store.state.main.company.uuid,
        data: {
          keyword_list: keywordList.value,
          status_id_list: uploadingToCompletedStatusIdList.value,
        },
      });
      if (res) {
        uploadedTotal.value = res.data.total;
      }
    };

    const fetchDynamicList = async () => {
      const res = await dispatchGetDynamicList(store, {
        dynamicListId: Number(router.currentRoute.params.id),
      });
      if (res && res.data) {
        dynamicList.value = res.data;
        lastCount.value = res.data.last_count;
      }
    };

    const findDynamicListNote = (dynamicListId, dynamicListResult): string => {
      const note =
        dynamicListResult.find((x) => x.dynamic_list_id === dynamicListId)
          .note ?? '';
      return note;
    };

    const fetchDynamicListResults = async (): Promise<void> => {
      isLoadingSdses.value = true;
      companyFiles.value.splice(0);

      const res = await dispatchGetDynamicListResults(store, {
        dynamicListId: Number(router.currentRoute.params.id),
        data: {
          sortState: sortState.value,
          keyword_list: keywordList.value,
          pdf_name: filterCriteria.value.pdfName,
          product_name: filterCriteria.value.productName,
          note: filterCriteria.value.note,
          cause: filterCriteria.value.cause,
          revised_at_start_date: filterCriteria.value.revisedAtStartDate,
          revised_at_end_date: filterCriteria.value.revisedAtEndDate,
          created_at_start_date: filterCriteria.value.createdAtStartDate,
          created_at_end_date: filterCriteria.value.createdAtEndDate,
          updated_at_start_date: filterCriteria.value.updatedAtStartDate,
          updated_at_end_date: filterCriteria.value.updatedAtEndDate,
          user_id_list: filterCriteria.value.uploadUsers.length
            ? filterCriteria.value.uploadUsers
            : [],
          tag_id_list: filterCriteria.value.tags.length
            ? filterCriteria.value.tags.map((tag) => tag.id)
            : [],
          department_id_list: filterCriteria.value.departments.map(
            (d) => d.uuid
          ),
          is_new_checklist: filterCriteria.value.isNewChecklist,
          is_new_sds: filterCriteria.value.isNewSds,
        },
      });
      if (res) {
        total.value = res.data.total;
        companyFiles.value.splice(0);
        companyFiles.value.push(
          ...res.data.map((x) => {
            return {
              id: x.id,
              dynamic_list_id: x.dynamic_list_id,
              uuid: x.sds.uuid,
              name: x.sds.name,
              note: findDynamicListNote(
                x.dynamic_list_id,
                x.sds.dynamic_list_result
              ),
              cause: x.cause,
              created_user: x.sds.created_user,
              departments: x.sds.departments,
              sds_products: x.sds.sds_products,
              revised_at: x.sds.revised_at,
              created_at: x.created_at,
              upload_at: x.sds.created_at,
              updated_at: x.updated_at,
              tags: x.sds.tags,
              is_new_checklist: x.is_new_checklist,
              is_new_sds: x.is_new_sds,
            };
          })
        );
      }

      isLoadingSdses.value = false;
    };

    const removeUploadUserId = async (index: string): Promise<void> => {
      filterCriteria.value.uploadUsers =
        filterCriteria.value.uploadUsers.filter((value) => value !== index);
      tempfilterCriteria.value.uploadUsers = [
        ...filterCriteria.value.uploadUsers,
      ];
      applyFilters();
    };

    const removeTagsId = async (index: number): Promise<void> => {
      filterCriteria.value.tags = filterCriteria.value.tags.filter(
        (value) => value.id !== index
      );
      tempfilterCriteria.value.tags = [...filterCriteria.value.tags];
      applyFilters();
    };

    const removeDepartmentId = async (uuid: string): Promise<void> => {
      filterCriteria.value.departments =
        filterCriteria.value.departments.filter((d) => d.uuid !== uuid);
      tempfilterCriteria.value.departments = [
        ...filterCriteria.value.departments,
      ];
      applyFilters();
    };

    const removePdfName = async (): Promise<void> => {
      filterCriteria.value.pdfName = '';
      tempfilterCriteria.value.pdfName = '';
      applyFilters();
    };

    const removeProductName = async (): Promise<void> => {
      filterCriteria.value.productName = '';
      tempfilterCriteria.value.productName = '';
      applyFilters();
    };

    const removeCause = async (): Promise<void> => {
      filterCriteria.value.cause = '';
      tempfilterCriteria.value.cause = '';
      applyFilters();
    };

    const removeNote = async (): Promise<void> => {
      filterCriteria.value.note = '';
      tempfilterCriteria.value.note = '';
      applyFilters();
    };

    const removeRevisedAtStartDate = async (): Promise<void> => {
      filterCriteria.value.revisedAtStartDate = '';
      tempfilterCriteria.value.revisedAtStartDate = '';
      applyFilters();
    };

    const removeRevisedAtEndDate = async (): Promise<void> => {
      filterCriteria.value.revisedAtEndDate = '';
      tempfilterCriteria.value.revisedAtEndDate = '';
      applyFilters();
    };

    const removeCreatedAtStartDate = async (): Promise<void> => {
      filterCriteria.value.createdAtStartDate = '';
      tempfilterCriteria.value.createdAtStartDate = '';
      applyFilters();
    };

    const removeCreatedAtEndDate = async (): Promise<void> => {
      filterCriteria.value.createdAtEndDate = '';
      tempfilterCriteria.value.createdAtEndDate = '';
      applyFilters();
    };

    const removeUpdatedAtStartDate = async (): Promise<void> => {
      filterCriteria.value.updatedAtStartDate = '';
      tempfilterCriteria.value.updatedAtStartDate = '';
      applyFilters();
    };

    const removeUpdatedAtEndDate = async (): Promise<void> => {
      filterCriteria.value.updatedAtEndDate = '';
      tempfilterCriteria.value.updatedAtEndDate = '';
      applyFilters();
    };

    const applyFilters = async (): Promise<void> => {
      resetPagenation();
      fetchDynamicListResults();
    };

    const setFilters = async (): Promise<void> => {
      filterCriteria.value = { ...tempfilterCriteria.value };
      applyFilters();
    };

    const exportDynamicListResults = async (): Promise<void> => {
      const dynamicListId = Number(router.currentRoute.params.id);
      const res = await dispatchGetExportDynamicListResults(store, {
        dynamicListId: dynamicListId,
      });
      if (res) {
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `export_link_${dynamicListId}.xlsx`;
        link.click();
        URL.revokeObjectURL(link.href);
      }
    };

    const fetchDynamicLists = async () => {
      dynamicLists.value.splice(0);

      const res = await dispatchGetDynamicLists(store, {
        page: page.value - 1,
        limit: MAX_LIMIT,
        showAll: false,
      });
      if (res && res.data) {
        dynamicLists.value = [...res.data.items];
      }
    };

    const exportCreateSimple = async (sdsId: string): Promise<void> => {
      try {
        dialogShow.value = true;

        const res = await dispatchGetExportCreateSimple(store, {
          sdsId: sdsId,
        });
        if (res) {
          const blob = new Blob([res.data], {
            type: 'application/zip',
          });

          const exportSds = companyFiles.value.find((x) => x.uuid === sdsId);
          if (exportSds) {
            const link = document.createElement('a');

            const fileName = removeLastPdfExtension(exportSds.name);
            const now = new Date();

            // 日本のタイムゾーンに変換
            const japanTime = new Intl.DateTimeFormat('ja-JP', {
              timeZone: 'Asia/Tokyo',
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }).format(now);
            // YYYY-MM-DD HH:mm:ss 形式に整形
            const timestampString = japanTime
              .replace(/\//g, '-')
              .replace(/\s/, ' ')
              .replace(/:/g, '');

            link.href = URL.createObjectURL(blob);
            link.download = `${fileName}_${timestampString}_CREATE SIMPLE v2.5.1.zip`;
            link.click();
            URL.revokeObjectURL(link.href);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        dialogShow.value = false;
      }
    };

    const exportCreateSimpleV3 = async (sdsId: string): Promise<void> => {
      try {
        dialogShow.value = true;

        const res = await dispatchGetExportCreateSimpleV3(store, {
          sdsId: sdsId,
        });
        if (res) {
          const blob = new Blob([res.data], {
            type: 'application/zip',
          });

          const exportSds = companyFiles.value.find((x) => x.uuid === sdsId);
          if (exportSds) {
            const link = document.createElement('a');

            const fileName = removeLastPdfExtension(exportSds.name);
            const now = new Date();

            // 日本のタイムゾーンに変換
            const japanTime = new Intl.DateTimeFormat('ja-JP', {
              timeZone: 'Asia/Tokyo',
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            }).format(now);
            // YYYY-MM-DD HH:mm:ss 形式に整形
            const timestampString = japanTime
              .replace(/\//g, '-')
              .replace(/\s/, ' ')
              .replace(/:/g, '');

            link.href = URL.createObjectURL(blob);
            link.download = `${fileName}_${timestampString}_CREATE SIMPLE v3.0.3.zip`;
            link.click();
            URL.revokeObjectURL(link.href);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        dialogShow.value = false;
      }
    };

    const editDynamicListResult = async (
      sdsId: string,
      dynamicListId: number,
      dynamicListResult: SdsDynamicListResult
    ): Promise<void> => {
      try {
        const res = await dispatchPutDynamicListResult(store, {
          sdsId: sdsId,
          dynamicListId: dynamicListId,
          dynamicListResult: dynamicListResult,
        });
        if (res) {
          await doFetchCompanySds(sdsId);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const addDynamicListResult = async (
      sdsId: string,
      dynamicListId: number
    ): Promise<void> => {
      try {
        const res = await dispatchPostDynamicListResult(store, {
          sdsId: sdsId,
          dynamicListId: dynamicListId,
        });
        if (res) {
          await doFetchCompanySds(sdsId);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const deleteDynamicListResult = async (
      sdsId: string,
      dynamicListId: number
    ): Promise<void> => {
      try {
        const res = await dispatchDeleteDynamicListResult(store, {
          sdsId: sdsId,
          dynamicListId: dynamicListId,
        });
        if (res) {
          await doFetchCompanySds(sdsId);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const filterCriteria: Ref<DynamicListResultFilterCriteria> = ref({
      uploadUsers: [], // Add a filter for the upload user
      tags: [], //Add a filter for the tags
      departments: [], // Add a filter for the department
      pdfName: '',
      productName: '',
      note: '',
      cause: '',
      revisedAtStartDate: '',
      revisedAtEndDate: '',
      createdAtStartDate: '',
      createdAtEndDate: '',
      updatedAtStartDate: '',
      updatedAtEndDate: '',
      isNewChecklist: false,
      isNewSds: false,
    });

    const tempfilterCriteria: Ref<DynamicListResultFilterCriteria> = ref({
      uploadUsers: [], // Add a filter for the upload user
      tags: [], //Add a filter for the tags
      departments: [], // Add a filter for the department
      pdfName: '',
      productName: '',
      note: '',
      cause: '',
      revisedAtStartDate: '',
      revisedAtEndDate: '',
      createdAtStartDate: '',
      createdAtEndDate: '',
      updatedAtStartDate: '',
      updatedAtEndDate: '',
      isNewChecklist: false,
      isNewSds: false,
    });

    const isUserSelected = (item) => {
      return filterCriteria.value.uploadUsers.some(
        (user) => user === item.uuid
      );
    };

    const isTagSelected = (item) => {
      return filterCriteria.value.tags.some((tag) => tag.id === item.id);
    };

    const sortState = ref<SortState>({
      name: 'none',
      revised_at: 'none',
      updated_at: 'none',
      created_at: 'none',
    });

    const getFullName = (userId: string): string => {
      const user = companyUploadUsers.value.find(
        (user) => user.uuid === userId
      );
      return user
        ? `${user.last_name || 'Unknown'} ${user.first_name || ''}`
        : '';
    };

    const onClickProductName = (name: string): void => {
      filterCriteria.value.productName = name;
      applyFilters();
    };
    const onClickTag = (tag: TagResponse): void => {
      if (
        !filterCriteria.value.tags.some(
          (item: TagResponse) => item.id === tag.id
        )
      ) {
        filterCriteria.value.tags.push(tag);
        applyFilters();
      }
    };

    const toggleSort = (column: string): void => {
      if (sortState.value[column] === 'none') {
        sortState.value[column] = 'asc';
      } else if (sortState.value[column] === 'asc') {
        sortState.value[column] = 'desc';
      } else {
        sortState.value[column] = 'none';
      }
      Object.keys(sortState.value).forEach((key) => {
        if (key !== column) {
          sortState.value[key] = 'none';
        }
      });

      fetchDynamicListResults();
    };

    const filteredUsers = computed(() => {
      const usersWithFullName = companyUploadUsers.value.map((user) => ({
        ...user,
        full_name:
          `${user.last_name} ${user.middle_name} ${user.first_name}`.toLowerCase(),
      }));

      if (searchUserText.value === '') {
        return usersWithFullName;
      }

      return usersWithFullName.filter((user) => {
        return user.full_name.includes(searchUserText.value.toLowerCase());
      });
    });

    const uploadUsersLabel = computed(() => {
      return tempfilterCriteria.value.uploadUsers.length === 0
        ? '選択してください'
        : '';
    });
    const tagsLabel = computed(() => {
      return tempfilterCriteria.value.tags.length === 0
        ? '選択してください'
        : '';
    });
    const departmentsLabel = computed(() => {
      return tempfilterCriteria.value.departments.length === 0
        ? '選択してください'
        : '';
    });

    const filteredTags = computed(() => {
      if (searchTagText.value === '') {
        return tags.value;
      }
      return tags.value.filter((item) => {
        const tag_name = `${item.name}`.toLowerCase();
        return tag_name.includes(searchTagText.value.toLowerCase());
      });
    });

    const filteredDepartments = computed(() => {
      if (searchDepartmentText.value === '') {
        return departments.value;
      }
      return departments.value.filter((item) => {
        const departments_name = `${item.name}`.toLowerCase();
        return departments_name.includes(
          searchDepartmentText.value.toLowerCase()
        );
      });
    });

    onMounted(async (): Promise<void> => {
      downloadExportData();
      window.onbeforeunload = confirmSave;
      await getCompanyTag();
      await doFetchCompanyUploadUsers();
      await getCompanyDepartments();

      if (router.currentRoute.query.uuid) {
        const name = router.currentRoute.query.name as string;
        keywordList.value.push(name);

        await fetchDynamicList();
        await fetchDynamicListResults();
        await fetchCompanySdsesCountWithAll();
        fetchDynamicLists();

        currentIndex.value = companyFiles.value.findIndex(
          (x) => x.uuid === router.currentRoute.query.uuid
        );
        const item = companyFiles.value.find(
          (x) => x.uuid === router.currentRoute.query.uuid
        );
        if (item) {
          showSdsDetail(item, companyFiles.value.indexOf(item));
        }
      } else {
        await fetchDynamicList();
        await fetchDynamicListResults();
        await fetchCompanySdsesCountWithAll();
        fetchDynamicLists();
      }
    });

    return {
      sortState,
      toggleSort,
      applyFilters,
      resetFilters,
      cancelFilters,
      setFilters,
      keywordList,
      pdfBlob,
      pdfName,
      limit,
      total,
      page,
      filterCriteria,
      tempfilterCriteria,
      toggleFilterPane,
      isDialogVisibleRevisedAtStartDate,
      isDialogVisibleRevisedAtEndDate,
      isDialogVisibleCreatedAtStartDate,
      isDialogVisibleCreatedAtEndDate,
      isDialogVisibleUpdatedAtStartDate,
      isDialogVisibleUpdatedAtEndDate,
      saveDate,
      isFilterPanelVisible,
      statusList,
      pdfDisplayOptions,
      sds,
      sdses,
      isLoadingSdses,
      isLoadingSds,
      isEditing,
      showSdsDetailView,
      productFormItems,
      materialFormItems,
      casFormItems,
      showPrevSds,
      showNextSds,
      hasPrevSds,
      hasNextSds,
      showSdsDetail,
      doUpdateCompanySds,
      cancelEdit,
      resetPagenation,
      resetSearchForm,
      doFetchCompanySdses,
      doFetchCompanySdsesCount,
      doFetchCompanySdsesCountWithCondition,
      doFetchCompanySds,
      doFetchPdf,
      downloadExportData,
      fetchCompanySdsesCountWithAll,
      fetchDynamicList,
      fetchDynamicListResults,
      dynamicList,
      formatToDate,
      exportDynamicListResults,
      uploadedTotal,
      lastCount,
      companyFiles,
      nextUpdateDate,
      exportCreateSimple,
      exportCreateSimpleV3,
      dialogShow,
      cancelExportCreateSimple,
      canExportCreateSimpleCount,
      canExportCreateSimpleCountV3,
      reanalysisSdsModalShow,
      openReanalysisSdsModal,
      closeReanalysisSdsModal,
      reanalysisSds,
      reanalysisSdsComment,
      dynamicLists,
      fetchDynamicLists,
      editDynamicListResult,
      addDynamicListResult,
      deleteDynamicListResult,
      activeSds,
      hasPermission,
      doFetchCompanyUploadUsers,
      departments,
      findDynamicListNote,
      statusTextList,
      companyUploadUsers,
      isUserSelected,
      isTagSelected,
      hasFilterCriteria,
      removeUploadUserId,
      removeTagsId,
      removeDepartmentId,
      removePdfName,
      removeProductName,
      removeCause,
      removeNote,
      removeRevisedAtStartDate,
      removeRevisedAtEndDate,
      removeCreatedAtStartDate,
      removeCreatedAtEndDate,
      removeUpdatedAtStartDate,
      removeUpdatedAtEndDate,
      getFullName,
      searchUserText,
      searchTagText,
      searchDepartmentText,
      filteredUsers,
      filteredTags,
      filteredDepartments,
      onClickProductName,
      onClickTag,
      uploadUsersLabel,
      tagsLabel,
      departmentsLabel,
      StatusId,
      P,
      companyDepartments,
    };
  },
});
